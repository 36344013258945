
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmPerson from '@/components/shared/TmPerson.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import { formatDate } from '@/services/dateTimeService'
import ServicesAutomationRulesLogsDropdown from '@/components/pages/services/automationRules/logs/ServicesAutomationRulesLogsDropdown.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { AutomationRuleLog } from '@/definitions/services/automationRules/types'

export default defineComponent({
  components: {
    TmTable,
    TmPerson,
    TmStatus,
    ServicesAutomationRulesLogsDropdown,
  },
  props: {
    headers: {
      type: Array as PropType<TableHeaders[]>,
      required: true,
    },
    items: {
      type: Array as PropType<AutomationRuleLog[]>,
      required: true,
    },
  },
  setup() {
    const getTo = (id: string): RouteLocationRaw => ({
      name: 'base.services.automationRules.logs.details',
      params: { id },
    })

    return {
      formatDate,
      getTo,
    }
  },
})
