
import {
  defineComponent,
  ref,
  computed
} from 'vue'
import ServicesAutomationRulesLogsTable from '@/components/pages/services/automationRules/logs/ServicesAutomationRulesLogsTable.vue'
import ServicesAutomationRulesLogsFilter from '@/components/pages/services/automationRules/logs/ServicesAutomationRulesLogsFilter.vue'
import ServicesAutomationRulesLogsEmpty from '@/components/pages/services/automationRules/logs/ServicesAutomationRulesLogsEmpty.vue'
import { useModes } from '@/compositions/modes'
import { getTableData } from '@/services/tableService'
import type { AutomationRuleLog } from '@/definitions/services/automationRules/types'
import type { TableHeaders } from '@/definitions/shared/types'
import { formatDate } from '@/services/dateTimeService'

export default defineComponent({
  components: {
    ServicesAutomationRulesLogsTable,
    ServicesAutomationRulesLogsFilter,
    ServicesAutomationRulesLogsEmpty,
  },
  setup() {
    const { isEmptyMode } = useModes()

    const search = ref('')
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Sender', value: 'sender-slot' },
      { text: 'Received by', value: 'receivedBy' },
      { text: 'Rule name', value: 'ruleName' },
      { text: 'Status', value: 'status-slot' },
      { text: 'Run time', value: 'runTime', format: (val: string) => formatDate(val) },
    ])

    const tableItems = getTableData('automationLogs') as AutomationRuleLog[]

    const filteredTableItems = computed(() => tableItems.filter((el) => (el.ruleName.toLocaleLowerCase().includes(search.value.toLocaleLowerCase()))))

    return {
      search,
      tableHeaders,
      filteredTableItems,
      isEmptyMode,
    }
  },
})
