
import { defineComponent, ref } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import TmDropdownDownload from '@/components/shared/dropdowns/TmDropdownDownload.vue'
import DateFilterDropdown from '@/components/layout/topFilter/dateFilter/DateFilterDropdown.vue'
import DateFilterCustom from '@/components/layout/topFilter/dateFilter/DateFilterCustom.vue'

export default defineComponent({
  components: {
    TopFilterButton,
    TopFilter,
    TmDropdownDownload,
    DateFilterCustom,
    DateFilterDropdown,
  },
  setup() {
    const dateFilter = ref('all')
    const customDate = ref()

    return {
      dateFilter,
      customDate,
    }
  },
})
