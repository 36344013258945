
import type { PropType } from 'vue'
import { defineComponent, toRefs, computed } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import useAutomationRuleOpenModal from '@/compositions/services/useAutomationRuleOpenModal'
import type { AutomationRuleLog } from '@/definitions/services/automationRules/types'

export default defineComponent({
  components: {
    TmDropdown,
    TmTableActionButton,
    TmDropdownItem,
  },
  props: {
    log: {
      type: Object as PropType<AutomationRuleLog>,
      required: true,
    },
  },
  setup(props) {
    const { log } = toRefs(props)

    const { openAutomationRuleDeleteModal } = useAutomationRuleOpenModal('log')

    const toDetails = computed(() => ({
      name: 'base.services.automationRules.logs.details',
      params: { id: log.value.id },
    }))

    return {
      openAutomationRuleDeleteModal,
      toDetails,
    }
  },
})
