import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_person = _resolveComponent("tm-person")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_services_automation_rules_logs_dropdown = _resolveComponent("services-automation-rules-logs-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.headers,
    items: _ctx.items,
    "items-name": "keyword"
  }, {
    "body-cell-sender-slot": _withCtx(({ row }) => [
      _createVNode(_component_router_link, {
        to: _ctx.getTo(row.id),
        class: "font-color-inherit font-weight-normal blue-on-hover"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_person, {
            name: (row.sender.type === 'phone') ? row.sender.phone : `${row.sender.firstName} ${row.sender.lastName}`,
            "avatar-color": row.sender.avatarColor,
            "avatar-icon": (row.sender.type === 'phone' && 'call') || '',
            "avatar-size": "small"
          }, null, 8, ["name", "avatar-color", "avatar-icon"])
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    "body-cell-status-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(row.status)), null, 16)
    ]),
    "body-cell-actions": _withCtx((props) => [
      _createVNode(_component_services_automation_rules_logs_dropdown, {
        log: props.row
      }, null, 8, ["log"])
    ]),
    _: 1
  }, 8, ["headers", "items"]))
}